<template>
    <div class="user-index">
        <div class="main-h1">Просмотр обращения</div>
        <div class="card">
            <div class="card-title">{{ appeal.title }}</div>
            <div class="card-body">
                <div class="card-body-title">Участники</div>
                <div class="row">
                    <div class="col-3">
                        <label class="form-label">
                            <div class="t">Пользователь</div>
                            <input v-model="appeal.user_name" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Стадия</div>
                <div class="row">
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Стадия</div>
                            <input v-model="appeal.stage_title" disabled/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Дедлайн стадии</div>
                            <input v-model="appeal.stage_dl" disabled/>
                        </label>
                    </div>
                    <div class="col-4">
                        <label class="form-label">
                            <div class="t">Ответственный</div>
                            <input v-model="appeal.implementer_name" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body">
                <div class="card-body-title">Даты</div>
                <div class="row">
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Обращение принято</div>
                            <input v-model="appeal.receipt_date" disabled/>
                        </label>
                    </div>
                    <div class="col-6">
                        <label class="form-label">
                            <div class="t">Дан ответ</div>
                            <input v-model="appeal.decision_date" disabled/>
                        </label>
                    </div>
                </div>
            </div>
            <div class="card-body" v-if="files.filter(item => item.type_id == 1).length">
                <div class="card-body-title">Документы</div>
                <file-list :list="files.filter(item => item.type_id == 1)" @updated="updateFiles"/>
            </div>
            <div class="card-body" v-if="files.filter(item => item.type_id == 4).length">
                <div class="card-body-title">Ответы</div>
                <file-list :list="files.filter(item => item.type_id == 4)" @updated="updateFiles"/>
            </div>
            <div class="card-body" v-if="connected.length">
                <div class="card-body-title">Связанные иски</div>
                <claim-list :list="connected"/>
            </div>
            <div class="card-body" v-if="$store.state.isAdmin() || $store.state.isImplementor() || $store.state.isArbiter()">
                <div class="card-body-title">История</div>
                <button class="btn blue" @click="logGet" v-if="!log.length">Получить</button>
                <table class="table-log" v-if="log.length">
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Автор</th>
                        <th>Изменение</th>
                    </tr>
                    </thead>
                    <tr v-for="item in log">
                        <td>{{ item.date }}</td>
                        <td>{{ item.name }}</td>
                        <td>{{ item.text }}</td>
                    </tr>
                </table>
            </div>
        </div>
    </div>
</template>

<script>
import fileList from "../../../components/file/list";
import claimList from '../../../components/claim/list.vue';

export default {
    name: "view",
    components: {fileList, claimList},
    data() {
        return {
            appeal: {},
            log: [],
            files: [],
            connected: []
        }
    },
    methods: {
        updateFiles() {
            this.getFiles();
            //this.logGet();
        },
        getClaimConnected(claimId) {
            this.$api.get(`claim/${claimId}`).then(res => {
                if (res.data.success) {
                    this.connected.push(res.data.data)
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
        getAppeal() {
            this.$api.get(`appeal/${this.$route.params.id}`).then(res => {
                if (res.data.success) {
                    this.appeal = res.data.data
                    //this.logGet();
                    if (res.data.data.claim_id > 0) {
                        this.getClaimConnected(res.data.data.claim_id)
                    }
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        logGet() {
            this.$api.get(`appeal/${this.$route.params.id}/log`).then(res => {
                if (res.data.success) {
                    this.log = res.data.data
                } else {
                    this.$store.commit('showNotify', {title: 'Ошибка', content: res.data.error, type: 'danger'});
                }
            })
        },
        getFiles() {
            this.$api.get(`appeal/${this.$route.params.id}/file`).then(res => {
                if (res.data.success) {
                    this.files = res.data.data
                } else {
                    //this.$store.commit('showNotify',{title:'Ошибка',content:res.data.error,type:'danger'});
                }
            })
        },
    },
    created() {
        this.getAppeal();
        this.getFiles();
    },
    watch: {
        '$route.params.id': function () {
            if (this.$route.params.id) {
                this.connected = [];
                this.getAppeal();
                this.getFiles();
            }
        }
    }
}
</script>

<style scoped>

</style>